@mixin box() {
  border: 1px solid get-color(light-grey);
  box-sizing: border-box;
  text-align: center;
  display: table;
  width: 100%;
  padding: .25em 1.250em;
  position: relative;
  transition: all .2s ease-out;

  h2 {
    @include font-1;
    color: get-color(sicpa-blue);
    margin-top: 10px;
  }

  i {
    margin-top: 15px;
  }

  p {
    @include font-2;
  }
}

.box {
  @include box();
}

.box-checkbox {
  @include box();
  label {
    color: get-color(sicpa-blue);
    padding: .5em 0 .375em 0;
    span {
      font-size: .75em;
    }
  }
}

.box-highlight {
  @include body-bold;
  display: table-cell;
  vertical-align: middle;
  padding: 1.25em 1.25em 1.25em 0;
  [dir='rtl'] & {
    padding: 1.25em 0 1.25em 1.25em;
  }
  h2 {
    font-size: 2.250em;
    color: get-color(sicpa-blue);
    margin: 0;
  }
  i {
    font-style: inherit;
    font-size: 3em;
  }
}

.box-body {
  display: table-cell;
  vertical-align: middle;
}

@each $name, $palette in $colors {
  .box-#{$name} {
    @include box();
    border: 1px solid mat-color($palette, 500);
    box-shadow: inset 0 -6px 0 mat-color($palette, 500);

    .box-highlight {
      color: mat-color($palette, 500);
    }

    &.box-horizontal {
      border: 1px solid mat-color($palette, 500);
      box-shadow: inset 6px 0 0 mat-color($palette, 500);

      [dir='rtl'] & {
        box-shadow: inset -6px 0 0 mat-color($palette, 500);
      }
    }
  }
}

.box-horizontal {
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}
