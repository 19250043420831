@import '~bootstrap/scss/utilities/align';
@import '~bootstrap/scss/utilities/float';
@import '~bootstrap/scss/utilities/visibility';


// Inspired from Bootstrap4 float utilities which doesn't have start/end float.
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    [dir='ltr'] {
      .float#{$infix}-start { float: left !important; }
      .float#{$infix}-end { float: right !important; }
    }
    [dir='rtl'] {
      .float#{$infix}-start  { float: right !important; }
      .float#{$infix}-end { float: left !important; }
    }
  }
}
