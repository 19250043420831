.description {
  max-width: 448px;
  line-height: 26px;
}

.img-404-error {
  background-image: url('../img/404Error.svg');
  height: 88px;
  width: 88px;
}
