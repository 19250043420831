@each $name, $palette in $colors {
  .label-#{$name} {
    background-color: mat-color($palette, 500);
    color: mat-contrast($palette, 500);

    color: mat-color($palette, 500);
    font-size: inherit;
    margin-left: 1.2em;
    position: relative;
    background-color: transparent;
    display: inline-block;

    [dir='rtl'] & {
      margin-left: unset;
      margin-right: 1.2em;
    }

    &:before {
      background-color: mat-color($palette, 500);
      content: "";
      width: 0.5em;
      height: 2em;
      position: absolute;
      left: -.9em;
      top: -.3em;
      border-radius: 1em;

      [dir='rtl'] & {
        left: unset;
        right: -.9em;
      }
    }

    @each $variation in $color-variations {
      &-#{$variation} {
        color: mix($white, mat-color($palette, 500), (100 - $variation)*1%);

        &:before {
          background-color: mix($white, mat-color($palette, 500), (100 - $variation)*1%);
        }
      }
    }
  }
}
