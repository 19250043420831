a,
a:visited {
  color: get-color(primary, 500);
  text-decoration: underline;

  &:not(.mat-flat-button):not(.mat-raised-button) {
    &:hover, &:focus {
      color: mat-color($s-dark-blue, 500);
    }
  }

  &.mat-icon-button {
    color: get-color(primary, 500);
  }
}
