.widget {
  margin-bottom: 1rem;

  .widget-link {
    transform: rotate(-45deg);

    [dir='rtl'] & {
      transform: rotate(-135deg);
    }

    .widget-link-icon {
      font-size: 1.05em;
    }
  }

  .mat-card-title {
    @include body-bold();
  }

  .mat-card-title {
    font-size: 1.25em;
  }

  .mat-card-subtitle {
    font-size: 1em;
  }

  .mat-card-title, mat-card-subtitle {
    line-height: 1.25em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .mat-card-avatar {
    height: 36px;
    width: 36px;
  }

  .widget-message {
    display: inline-block;
    height: 1.8em;
    padding: 0.2rem;
    border-radius: 2px;
    overflow: hidden;

    .widget-message-icon {
      font-size: 0.5em;
    }

    .widget-message-text {
      @include body-bold();
      position: relative;
      top: 1px;
    }
  }

  /********
   * CARD *
   ********/
  &.widget-card {
    // height: 9.5em; // TODO: remove this
    .widget-value {
      @include body-bold();
      margin: 0;
      font-size: 2.375em;

      .widget-unit {
        font-size: 0.421em;
        @include body-bold();
      }
    }
  }

  /*********
   * CHART *
   *********/
  .widget-canvas {
    display: block;
    height: 16.5em;
  }

  .widget-chart {
    //height: 20em; // TODO: remove this
  }
}

