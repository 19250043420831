@mixin accordion-parent() {
  display: inline-block;
  width: 100%;

  &:hover {
    color: $white;
    &:after {
      color: $white;
    }
  }

  &:after {
    position: absolute;
    right: 1em;
    // .icon-2;
    content: "+";

    [dir='rtl'] & {
      right: unset;
      left: 1em;
    }
  }

  &.expanded:after {
    content: "-";
  }
}

@mixin icon-font() {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

//Instead of repeating the code from both keyframes as it was in the Atomic version, we regroup them here using a function
@mixin keyframes-animation($name) {
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes-animation(load-ltr) {
  0% {
    left: 0;
    right: auto;
    width: 0;
  }

  49% {
    left: 0;
    right: 0;
    width: 100%;
  }

  50% {
    left: auto;
    right: 0;
    width: 100%;
  }

  100% {
    left: auto;
    right: 0;
    width: 0;
  }
}

@include keyframes-animation(load-rtl) {
  0% {
    left: auto;
    right: 0;
    width: 0;
  }

  49% {
    left: 0;
    right: 0;
    width: 100%;
  }

  50% {
    left: 0;
    right: auto;
    width: 100%;
  }

  100% {
    left: 0;
    right: auto;
    width: 0;
  }
}

// The loading animation. Can be used in small elements to signal that it is loading
@mixin loading() {
  position: relative;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 5px;
    left: 0;
  }

  &::before {
    right: 0;
    background-color: mat-color($s-white,500);
  }

  &::after {
    background-color: mat-color($s-dark-blue,500);
    z-index: $cdk-z-index-overlay + 1;
    animation: load-ltr 1.7s ease-in 0.2s infinite;
    [dir='rtl'] & {
      animation: load-rtl 1.7s ease-in 0.2s infinite;
    }
  }
}

@mixin overlay-layer() {
  content: '';
  position: absolute;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background-color: mat-color($s-white, 500, 0.7);
  z-index: $cdk-z-index-overlay;
}
