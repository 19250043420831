.has-help {
  flex-grow: 4;
  overflow: auto;
}
.page-help {
  flex-grow: 1;
  min-width: 350px;
  max-width: 550px;
  background-color: get-color(cloud-grey);
  padding: 10px 20px;

  .help-sidebar {
    height: 100%;
    overflow: hidden;
    animation: fade-in both .8s;
  }
}
// invert help icon on rtl
.fa-question-circle {
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}
