.language-switcher {
  color: get-color(primary);

  .btn-link {
    border-color: transparent;
    color: get-color(primary);
    font-weight: 400;
    min-width: 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
     text-decoration: underline;
    }
  }

  button, a {
    font-size: em(12.75);
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
    &:focus {
      color: get-color(primary);
    }
    &.active {
      @include body-bold();
    }
  }
}
