.mat-menu-content {
  padding-bottom: 0 !important;
}

.export-menu-button.mat-flat-button {
  border-color: mat-color($s-light-grey, 500) !important;
  border-radius: 4px;
  color: mat-color($s-dark-grey, 500) !important;

  &:hover {
    background-color: mat-color($s-blue, 500, 0.04) !important;
  }

  &.menu-open {
    background-color: mat-color($s-blue, 500, 0.04) !important;
    color: mat-color($s-blue, 500);
  }

  .mat-ripple-element {
    background-color: mat-color($s-blue, 500, 0.04) !important;
  }
}

#export-form {
  .mat-menu-item {
    line-height: 21px;
    height: 32px;
    color: mat-color($s-dark-grey, 500);
    padding-right: 24px;
  }

  .mat-radio-button, .mat-checkbox {
    margin-top: 4px;

    .mat-ripple {
      display: none;
    }
  }

  .mat-radio-button {
    width: 120%;
  }

  .mat-divider {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .export-menu-title {
    color: mat-color($s-dark-grey, 500);
    padding: 16px;
    @include body-bold();
  }

  .export-button {
    background-color: mat-color($s-blue, 500);
    text-align: center;
    color: mat-color($s-white, 500);
    line-height: 48px;
    height: 48px;

    &:hover {
      background-color: mat-color($s-blue, 900);
    }

    &:disabled {
      background-color: mat-color($s-light-grey, 500);
    }
  }

  .columns-selection {
    max-height: 176px;
    overflow-y: auto;
  }
}
