@mixin font-1() {
  @include body-regular();
  font-size: 1.5em;
}

@mixin font-2() {
  @include body-regular();
  font-size: 1.125em;
}

@mixin font-3() {
  @include body-regular();
  font-size: 1em;
}

@mixin font-4() {
  @include body-regular();
  font-size: .875em;
}

.xxlarge {
  font-size: 4em;
}

.xlarge {
  font-size: 3em;
}

.large {
  font-size: 2.250em;
}

.medium {
  font-size: 1.5em;
}

.default {
  font-size: 1em;
}

.small {
  font-size: .875em;
}

.tiny {
  font-size: .625em;
}

h2 {
  margin-bottom: 24px;
  font-size: em(32);
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  color: get-color(sicpa-blue);
}
