#header,
.header {
  padding: 0;
  background-color: get-color(cloud-grey);
  border-bottom: 1px solid get-color(light-grey);
  height: $headerHeight;

  a {
    text-decoration: none;
  }

  .header-item {
    margin-right: 1em;

    [dir='rtl'] & {
      margin-left: 1em;
      margin-right: unset;
    }
  }

  scpa-breadcrumb {
    padding-left: $contentPadding;

    [dir='rtl'] & {
      padding-left: unset;
      padding-right: $contentPadding;
    }
  }

  @include media-breakpoint-down(md) {
    scpa-breadcrumb {
      padding-left: $contentPaddingMD;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: $contentPaddingMD;
      }
    }
  }
}

.logo-header {
  @extend .full;
  border-bottom: em(1) solid get-color(light-grey);
  @include body-regular();
  color: get-color(dark-grey);
  letter-spacing: em(4);
  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  z-index: 7;
  transition: all linear .2s;
  display: flex;
  align-items: center;
  padding-left: $contentPadding;
  position: fixed;
  margin-left: $menuCollapsedWidth;

  [dir='rtl'] & {
    margin-left: unset;
    margin-right: $menuCollapsedWidth;
    padding-left: unset;
    padding-right: $contentPadding;
    left: unset;
    right: 0;
  }

  p {
    margin: em(22.5) 0 em(22.5) 0;
  }

  img {
    height: em(32);
    margin: 0 em(12.5) 0 0;

    [dir='rtl'] & {
      margin: 0 0 0 em(12.5);
    }
  }

  &.collapsed {
    @extend .collapsed;

    p {
      margin: em(11) 0 em(11) 0;
    }

    img {
      height: em(16);
      margin: em(10) em(10) em(10) em(20);
    }
  }
}

@include media-breakpoint-down(md) {
  .logo-header {
    padding-left: $contentPaddingMD;

    [dir='rtl'] & {
      padding-left: unset;
      padding-right: $contentPaddingMD;
    }
  }
}

.logo-image {
  position: relative;
  float: left;
}

.project-name {
  position: relative;
  float: left;
}

.logo-header-box {
  @extend .full;
  border-bottom: em(1) solid get-color(sicpa-blue);
  transition: all linear .2s;
  &.collapsed {
    @extend .collapsed;
  }
}

.full {
  font-size: 20px;
  height: em(60);
}

.collapsed {
  font-size: 14px;
  height: em(36);
}

.header-margin-collapsed {
  margin-top: 46px;
}

.header-margin {
  margin-top: 75px;
}
