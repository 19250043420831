mat-form-field {
  .mat-form-field-underline.mat-form-field-underline {
    /* Change color of underline */
    background-color: get-color(light-grey);

    .mat-form-field-ripple {
      /* Change color of underline when focused */
      background-color: get-color(light-grey);
    }
  }

  &.mat-focused {
    .mat-placeholder-required {
      color: get-color(sicpa-blue);
    }
  }
}
