.overlay {
  position : relative;
  overflow : hidden;
  &:after {
    @include overlay-layer();
  }
}

.overlay-layer {
  @include overlay-layer();
}
