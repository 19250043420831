mat-horizontal-stepper {
  mat-step-header {
    .mat-step-icon {
      box-shadow: 0 0 0 0.125em $white, 0 0 0 0.188em get-color(default);
      width: 1.75em;
      height: 1.75em;
    }

    mat-step-label-active {
      background-color: get-color(success);
    }
  }
}
