scpa-menu {
  a.active {
    background-color: get-color(sicpa-blue);
  }

  .mat-icon {
    width: 14px;
    height: 16px;
  }

  .accordion {
    padding-left: 0;
    user-select: none;

    [dir='rtl'] & {
      padding-left: unset;
      padding-right: 0;
    }

    a,
    a:visited {
      color: get-color(light-grey);
      text-decoration: none;

      &:not(.mat-flat-button):not(.mat-raised-button) {
        &:hover, &:focus {
          color: white;
        }
      }
    }

    li {
      .accordion-item-content {
        // .small;
        display: none;
        font-size: 14px;
        line-height: 1.5em;
        &.show {
          display: block !important;
        }
      }

      .accordion-item-title {
        cursor: pointer;

        &:hover {
          color: white;
        }
      }

      .entry-has-submenus {
        @include accordion-parent;
      }

      list-style: none;
      color: get-color(light-grey);
      position: relative;
      margin: .75em 0;
      padding: .5em 1.5em;

      &:before {
        content: "\00a0";
        display: block;
        position: absolute;
        height: 0;
        top: 0;
        left: 0;
        width: .5em;
        background-color: get-color(primary);
        transition: all 0.3s ease-in-out 0s;

        [dir='rtl'] & {
          left: unset;
          right: 0;
        }
      }

      &.expanded {
        color: $white;

        &:before {
          height: 100%;
        }

        & .accordion-item-title {
          margin-bottom: 1em;
        }
      }
    }

    &--menu {
      .accordion-item-title {
        @include accordion-parent;
      }
    }

    &--inverted {
      .accordion-item-title {
        color: lighten(get-color(dark-grey), 5%);

        &:hover, &:hover:after {
          color: get-color(dark-grey);
        }
      }

      .accordion-item-content, li .accordion-item-content a {
        color: lighten(get-color(dark-grey), 5%);
      }
    }
  }
}

