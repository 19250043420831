.breadcrumb {
  display: inline-block;
  padding: 0;
  list-style: none;
  font-size: $defaultFontSize;

  > li {
    display: inline-block;
    @include body-regular();
    font-size: em(18);
    color: get-color(default);

    + li:not(.first):before {
      color: get-color(default);
      content: "/\00a0";

      display: inline-block;
      padding: 0 em(11.25);
      vertical-align: 0;

      &:last-child:before {
        color: get-color(default);
      }
    }

    > .active {
      color: get-color(light-grey);
    }

    a {
      color: get-color(primary);
      cursor: pointer;

      &:focus,
      &:hover,
      &:visited:focus,
      &:visited:hover {
        color: get-color(dark-blue);
      }
    }
  }

  // invert home icon on rtl
  .fa-home {
    [dir='rtl'] & {
      transform: scaleX(-1);
    }
  }
}
