button, a {
  &.mat-button,
  &.mat-flat-button,
  &.mat-icon-button,
  &.mat-stroked-button {
    border-radius: 20px/50%;
  }
}

button .plus,
input[type='button'] .plus {
  font-size: 1.5em;
  display: inline-block;
  position: relative;
  top: -2px;
}

body {
  button[disabled] {
    &.mat-button.mat-button-disabled,
    &.mat-icon-button.mat-button-disabled,
    &.mat-stroked-button.mat-button-disabled,
    &.mat-flat-button.mat-button-disabled,
    &.mat-raised-button.mat-button-disabled {
      cursor: not-allowed;
      color: white;
    }
  }
}

button,
a {
  &.mat-button-base.btn-sm {
    padding: 0.2rem 0.4rem;
    font-size: 0.8rem;
    line-height: 1.4;
    border-radius: 1rem;
  }

  i {
    padding-bottom: 0.2em;
  }
}

button,
a,
a:visited{
  &.mat-button-base:not(.mat-icon-button):not(.mat-primary):not(.mat-accent):not(.mat-warn):not(.mat-danger):not(.mat-warning):not(.mat-sicpa-blue):not(.mat-success):not(.mat-button-disabled)
  {
    border: 1px solid get-color(primary, 500);
    color: get-color(primary, 500);

    &:hover, &:focus {
      color: mat-color($s-dark-blue, 500);
      border-color: mat-color($s-dark-blue, 500);
    }
  }
}

.mat-stroked-button {
  &.mat-accent {
    border: 1px solid mat-color($sicpa-accent-light);
  }

  &.mat-warn {
    border: 1px solid mat-color($sicpa-warn-light);
  }
}

.button-group {
  button, a {
    margin-inline-start: 20px;

    &:first-child {
      margin-inline-start: 0;
    }
  }
}
