#advanced-search-toggle {
  .toggle-button {
    border: 1px solid get-color(light-grey);
    border-top: none;
    border-radius: 0 0 17px 17px;
    cursor: pointer;
    position: absolute;
    left: auto;
    padding: 2px 15px;
    color: get-color(primary);
    background-color: mat-color($s-white);
    z-index: 2;
  }
}

.advanced-search {
  background-color: white;
  padding: 0;
  position: relative;
  border-bottom: 1px solid get-color(light-grey);
  height: auto;
  overflow: hidden;
}
