@import '~bootstrap/scss/mixins/breakpoints';

// Margin/Padding left and right should be adapted to work correctly with RTL languages
$spacer: 1rem !default;
$spacers: () !default;
/* prettier-ignore */
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4
    2: ($spacer * .5), // 8
    3: $spacer, // 16
    4: ($spacer * 1.5),   // 24
    5: ($spacer * 3), // 48
  ),
  $spacers
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}r#{$infix}-#{$size} {
          [dir='rtl'] & {
            #{$prop}-right: unset !important;
            #{$prop}-left: $length !important;
          }
        }
        .#{$abbrev}l#{$infix}-#{$size} {
          [dir='rtl'] & {
            #{$prop}-left: unset !important;
            #{$prop}-right: $length !important;
          }
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .mr#{$infix}-n#{$size} {
          [dir='rtl'] & {
            margin-right: unset !important;
            margin-left: -$length !important;
          }
        }
        .ml#{$infix}-n#{$size} {
          [dir='rtl'] & {
            margin-left: unset !important;
            margin-right: -$length !important;
          }
        }
      }
    }

    // Some special margin utils
    .mr#{$infix}-auto {
      [dir='rtl'] & {
        margin-right: unset !important;
        margin-left: auto !important;
      }
    }

    .ml#{$infix}-auto {
      [dir='rtl'] & {
        margin-left: unset !important;
        margin-right: auto !important;
      }
    }
  }
}

// Font weight and italics
.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

@each $name, $palette in $colors {
  .bg-#{$name} {
    background-color: mat-color($palette, 500);
    color: mat-contrast($palette, 500);
  }

  .bg-#{$name}-light {
    background-color: mix($white, mat-color($palette, 500), 60%);
    color: mat-contrast($palette, 500);
  }

  .bg-#{$name}-gradient {
    // TODO: keep or remove
    background-image: linear-gradient(to right bottom, mat-color($palette, 100), mat-color($palette, 500));
  }

  .text-#{$name} {
    color: mat-color($palette, 500);
  }

  button.mat-button.mat-#{$name}:not([disabled]) {
    color: mat-color($palette, 500);
  }

  button.mat-raised-button.mat-#{$name}:not([disabled]) {
    background-color: mat-color($palette, 500);
    color: mat-contrast($palette, 500);
  }

  button.mat-stroked-button.mat-#{$name}:not([disabled]) {
    color: mat-color($palette, 500);
    border: 1px solid mat-color($palette, 500);
  }

  button.mat-flat-button.mat-#{$name}:not([disabled]) {
    background-color: mat-color($palette, 500);
    color: mat-contrast($palette, 500);
  }
}

// Text position
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.hidden {
  display: none !important;
}

.flex--group {
  display: flex;
  position: relative;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}
