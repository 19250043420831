ul {
  list-style: disc;
  margin: 0;
}

.no-bullet {
  list-style: none;
}

dl dt, dl dd {
  margin-bottom: 5px;
}

.list-side-by-side {
  dt {
    float: left;
    margin-right: 10%;
    max-width: 40%;
    font-weight: 700;
    [dir='rtl'] & {
      float: right;
      margin-right: unset;
      margin-left: 10%;
    }
  }

  dd {
    margin-left: 50%;
    margin-bottom: .5em;
    [dir='rtl'] & {
      margin-left: unset;
      margin-right: 50%;
    }
  }

  dd::after {
    content: " ";
    display: block;
    clear: left;
    [dir='rtl'] & {
      clear: right;
    }
  }
}
