@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500';

// Fontawesome
$fa-font-path: "../fonts" !default;
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';

// 7-1 Pattern
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'base/animations';
@import 'base/typography';
@import 'base/utilities';
@import 'base/bootstrap-utilities';

@import 'components/advanced-search';
@import 'components/box';
@import 'components/button';
@import 'components/checkbox';
@import 'components/chips';
@import 'components/datepicker';
@import 'components/export';
@import 'components/forms';
@import 'components/help';
@import 'components/input';
@import 'components/labels';
@import 'components/language-switcher';
@import 'components/links';
@import 'components/lists';
@import 'components/loading';
@import 'components/overlay';
@import 'components/rule';
@import 'components/selection';
@import 'components/stepper';
@import 'components/table';
@import 'components/toast';
@import 'components/well';
@import 'components/widget';
@import 'components/page404';

@import 'layout/layout';
@import 'layout/breadcrumb';
@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/menu';

*, :after, :before {
  box-sizing: border-box;
}
