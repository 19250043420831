.well {
  @include font-2;
  border-radius: 0;
  padding: 1.875em;
  background-color: mat-color($s-cloud-grey, 500);
  border: 1px solid mat-color($s-light-grey, 500);
  min-height: 20px;
  margin-bottom: 20px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
