@import '../base/utilities';

.mat-row:not(:nth-child(4n + 1)) {
  background-color: #ffffff;
}

scpa-layout .mat-header-cell,
scpa-layout .mat-cell {
  font-size: em(15);
}

.empty-table {
  @extend .text-center;
  color: mat-color($s-dark-grey, 500);
}

.mat-table {
  border: solid 1px get-color(light-grey);

  tr:last-child td {
    border-bottom: 0;
  }
}

mat-card.table-as-card {
  border: solid mat-color($s-light-grey, 500);
  border-width: 0 1px 0 1px;
  border-radius: 0;
  max-width: 600px;
  box-shadow: none;

  &:first-of-type {
    border-top: 1px solid mat-color($s-light-grey, 500);
  }

  &:last-of-type {
    border-bottom: 1px solid mat-color($s-light-grey, 500);
  }

  &:nth-of-type(even) {
    background-color: mat-color($s-white, 500);
  }

  &:nth-of-type(odd) {
    background-color: mat-color($s-blue-grey, 50);
  }

  &.card-selected {
    background-color: mat-color($s-light-grey, 500);
  }

  td,
  th {
    width: 35%;
  }

  mat-card-content {
    text-align: left;
  }

  mat-card-content th {
    color: mat-color($s-default, 500);
    font-weight: normal;
  }

  mat-card mat-card-content table tr > * {
    padding-bottom: 1.5em;
  }

  .mat-card {
    box-shadow: none;
  }

  td.icons > * {
    padding-right: 10px;
  }

  mat-checkbox .mat-checkbox-frame {
    border: 1px solid mat-color($s-light-grey, 500);
    background-color: mat-color($s-white, 500);
    border-radius: 5px;
  }
}
