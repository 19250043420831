.mat-calendar-previous-button::after {
  [dir='rtl'] & {
    transform: translateX(2px) rotate(135deg);
  }
}

.mat-calendar-next-button::after {
  [dir='rtl'] & {
    transform: translateX(-2px) rotate(-135deg);
  }
}

.mat-date-range-input-start-wrapper {
  line-height: 20px;
}
