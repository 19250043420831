.toast {
  font-size: .9rem;
  color: get-color(dark-grey);
  border-radius: .3rem;
  padding: 1rem 1rem 1rem 4.2rem;
  border: 1px solid get-color(light-grey);
  box-shadow: 5px 5px 5px fade($white, 10%);
  width: 23rem;
  height: fit-content;
  position: relative;
  margin-bottom: 1em;
  background-color: $white;
  opacity: 98%;
  animation: fade-in both .8s;

  [dir='rtl'] & {
    padding: 1rem 4.2rem 1rem 1rem;
  }

  p {
    margin: 0;
  }
  p, li {
    word-wrap: break-word;
  }

  ul {
    padding-left: em(15);
  }

  &:after {
    font-size: .9rem;
    @include icon-font;
    font-weight: 900;
    top: -.0625rem;
    position: absolute;
    border-radius: 3px 0 0 3px;
    left: -.0625rem;
    width: 3.5rem;
    color: #fff;
    height: calc(100% + .083em);
    display: flex;
    align-items: center;

    [dir='rtl'] & {
      left: unset;
      right: -.0625rem;
      border-radius: 0 3px 3px 0;
    }
  }

  .toast-title {
    font-weight: bold;
  }

  .toast-id {
    color: get-color(default);
  }

  .toast-close-button {
    &:after {
      top: .5rem;
      right: .5rem;
      @include icon-font;
      content: '\f00d';
      position: absolute;
      color: get-color(default);
      cursor: pointer;

      [dir='rtl'] & {
        left: .5rem;
        right: unset;
      }
    }
  }

  &-success {
    &:after {
      font-size: 1.5rem;
      background-color: get-color(success);
      content: '\f00c';
      padding-left: 1.1rem;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 1.1rem;
      }
    }
  }
  &-info {
    &:after {
      font-size: 1.5rem;
      background-color: get-color(sicpa-blue);
      content: '\f129';
      padding-left: 1.5rem;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 1.5rem;
      }
    }
  }
  &-warning {
    &:after {
      font-size: 1.5rem;
      background-color: get-color(warning);
      content: '\f12a';
      padding-left: 1.5rem;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 1.5rem;
      }
    }
  }
  &-danger {
    &:after {
      font-size: 1.5rem;
      background-color: get-color(danger);
      content: '\f00d';
      padding-left: 1.2rem;

      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 1.2rem;
      }
    }
  }
}

.toast-container {
  z-index: $cdk-z-index-overlay + 2;
  position: fixed;
  right: 20px;
  top: 50px;

  [dir='rtl'] & {
    left: 20px;
    right: unset;
  }
}
