footer {
  position: relative;
}

.footer {
  @include font-4();
  border-top: 1px solid get-color(light-grey);
  background-color: get-color(cloud-grey);
  color: get-color(dark-grey);
  text-align: center;
  padding: 1em;
  display: flex;
  flex: auto;
  justify-content: center;
}
