*:not(mat-icon) {
  @include body-regular();
}

a {
  color: get-color(sicpa-blue);
}

body,
html {
  margin: 0;
  padding: 0;
  @include body-regular();
}

#content {
  display: flex;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  min-height: 100vh;
  background-color: get-color(cloud-grey);
}

.page-content {
  padding: $contentPadding;
  border-right: 1px solid get-color(light-grey);
  background-color: $white;
}

@include media-breakpoint-down(md) {
  .page-content {
    padding: $contentPaddingMD;
  }
}

scpa-layout {
  .fixed-container {
    width: $menuCollapsedWidth;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $cdk-z-index-overlay - 1;
    background-color: get-color(sicpa-blue);
    user-select: none;

    [dir='rtl'] & {
      left: unset;
      right: 0;
    }

    .menu-burger {
      border: none;
      background-color: get-color(dark-blue);
      height: $headerHeight;
      position: relative;
      width: 100%;

      .bar {
        background: white;
        display: block;
        height: 2px;
        margin: 9px auto;
        width: 50%;
      }
      &:after {
        border: 2px solid get-color(primary);
        border-right-style: none;
        border-top-style: none;
        content: "";
        height: 10px;
        width: 10px;
        position: absolute;
        right: 8px;
        top: 24px;
        transform: rotate(-135deg);
        transition: .25s;
        [dir='rtl'] & {
          right: unset;
          left: 8px;
          transform: rotate(45deg);
        }
      }
      &-closed {
        &:after {
          right: .425em;
          transform: rotate(45deg);
          [dir='rtl'] & {
            right: unset;
            left: .425em;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .sidenav-container {
    height: 100%;

    .connection {
      height: $headerHeight;
      background-color: get-color(sicpa-blue);
      padding: 10px 25px;
      display: flex;
      align-items: center;
    }

    scpa-aaa-logout {
      right: 0;
      margin-left: auto;

      [dir='rtl'] & {
        right: unset;
        left: 0;
        margin-left: unset;
        margin-right: auto;
      }

      button.mat-icon-button {
        float: right;
        margin-right: -15px;

        [dir='rtl'] & {
          float: left;
          margin-right: unset;
          margin-left: -15px;
        }
      }
    }
  }

  .sidenav {
    width: 365px;
  }

  mat-sidenav-content.sidemenu {
    left: $menuCollapsedWidth;
    margin-right: $menuCollapsedWidth;
    overflow-x: hidden;

    [dir='rtl'] & {
      left: unset;
      margin-right: unset;
      right: $menuCollapsedWidth;
      margin-left: $menuCollapsedWidth;
    }
  }

  .mat-drawer {
    background-color: get-color(medium-blue);

    &.mat-drawer-opened {
      margin-left: $menuCollapsedWidth;

      [dir='rtl'] & {
        margin-left: unset;
        margin-right: $menuCollapsedWidth;
      }
    }

    * {
      color: get-color(blue-grey);
    }
  }

  .mat-toolbar {
    background-color: get-color(sicpa-blue);

    &.mat-primary {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .user {
    width: 100%;
    height: $menuCollapsedWidth;
    position: relative;
    background-color: get-color(sicpa-blue);

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 54px;
      height: 54px;
      border: solid 2px $white;
      border-radius: 28px;
      left: 7px;
      top: 7px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 44px;
      height: 44px;
      top: 12px;
      left: 12px;
      border-radius: 22px;
      background: get-color(light-grey);
    }

    i {
      position: absolute;
      cursor: pointer;
      font-size: 1.5rem;
      line-height: 1.6em;
      color: $white;
      left: 1em;
      top: 0.6em;
      display: block;
      clear: both;
      z-index: 2;
    }
  }

  mat-progress-bar {
    z-index: 9;
    position: fixed;
    top: 0;
    right: 0;
  }
}
