mat-form-field[appearance='fill'] {
  margin-bottom: 10px;
}

.mat-form-field {
  width: 100%;
}

$component-list: 'group', 'checkbox', 'radio', 'dropdown', 'textarea', 'text', 'number', 'date', 'image';
$prefix: 'scpa-field-form-';

@each $c in $component-list {
  #{$prefix}#{$c} {
    display: block;
    margin-bottom: 20px;
  }
}

.mat-select {
  padding-top: 3px;
  padding-bottom: 2px;
}

.mat-radio-label-content {
  font-size: em(14);
}
scpa-layout .mat-radio-outer-circle {
  width: 18px;
  height: 18px;
  border-width: 1px;
}
scpa-layout .mat-radio-inner-circle {
  background-color: get-color(sicpa-blue);
  width: 18px;
  height: 18px;
}

.mat-radio-label-content {
  font-size: em(15);
}

label.mat-form-field-label {
  line-height: 1.2em;
}

scpa-field-form-radio {
  mat-label {
    margin-bottom: 20px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    font-weight: 500;
  }

  .radio {
    margin-bottom: 5px;
  }
}
