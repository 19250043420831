.mat-chip.mat-standard-chip {
  color: get-color(dark-grey);
  border: 1px solid get-color(light-grey);
  background-color: transparent;
}

.mat-chip-remove {
  padding: 2px;
  color: get-color(light-grey);
}
