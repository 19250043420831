@import 'overlay';

.loading {
  @include loading();
}

// Will display a white transparent layer on top of element while loading, used for pages
.loading-page {
  @include loading();
  &:before {
    @include overlay-layer();
  }
}
