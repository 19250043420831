@font-face {
  font-family: 'pt_sansbold';
  src: url('../fonts/ptsans/PTS75F_W.eot');
  src: url('../fonts/ptsans/PTS75F_W.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ptsans/PTS75F_W.woff') format('woff'),
  url('../fonts/ptsans/PTS75F_W.ttf') format('truetype'),
  url('../fonts/ptsans/PTS75F_W.svg#pt_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_sansregular';
  src: url('../fonts/ptsans/PTS55F_W.eot');
  src: url('../fonts/ptsans/PTS55F_W.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ptsans/PTS55F_W.woff') format('woff'),
  url('../fonts/ptsans/PTS55F_W.ttf') format('truetype'),
  url('../fonts/ptsans/PTS55F_W.svg#pt_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin body-regular() {
  font-family: 'pt_sansregular', sans-serif;
}

@mixin body-bold() {
  font-family: 'pt_sansbold', sans-serif;
}
